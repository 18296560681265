
// gatsby-browser.js
import React from 'react';
import { ThemeProvider } from './src/context/ThemeContext';

export const wrapRootElement = ({ element }) => (
  <ThemeProvider>{element}</ThemeProvider>
);

export const onInitialClientRender = () => {
  const savedTheme = localStorage.getItem('isDarkMode');
  const isDarkMode = savedTheme !== null ? JSON.parse(savedTheme) : true;
  
  const link = document.getElementById('mode-stylesheet');

  // if (link) {
    
  //   const currentHref = link.getAttribute('href');
  //   const newHref = isDarkMode 
  //     ? currentHref.replace('Light', '') 
  //     : currentHref.replace(/\.css$/, 'Light.css');
  //   link.href = newHref;
  // }
};
